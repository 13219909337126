import * as React from 'react';
import { PureComponent } from 'react';
import styled from 'styled-components';
import { AppColor } from '../../../constants/Color';

interface Props{
  authors: string[];
}

const Span = styled.span<{}>`
  color: ${AppColor.SECONDARY};
  font-size: 0.9rem;

  margin-bottom: 0.2rem;
`;

export class ArticleAuthors extends PureComponent<Props>{

  public render() : JSX.Element | null{
    if(this.props.authors.length === 0) return null;
    return(
      <Span>
        Written by {this.props.authors.join(", ")}
      </Span>
    );
  }
}