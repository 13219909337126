export interface IMember{
  id: string;
  display: string;
  avater: string;
  bio: string;
  domains: string[];
  site?: string;
  twitter?: string;
  isRegular: boolean;
}

const hassaku: IMember = {
  id: "Hassaku",
  display: "はっさくみんと",
  avater: "hassaku.png",
  bio: `果物が好きなソフトウェアエンジニア
  気まぐれな性格`,
  domains: ["アプリケーションアーキテクチャ", "Unity", "Web", "UIデザイン", "カウンセリング", "メンタルヘルスケア"],
  site: `https://hassakulab.com`,
  twitter: "HassakuTb",
  isRegular: true,
};

const duce: IMember = {
  id: "Duce",
  display: "Duce",
  avater: "duce.png",
  bio: `へんたいふしんしゃさん`,
  domains: ["アクション", "サウンド", "ツール", "機械学習", "音声認識"],
  twitter: "duce023",
  isRegular: true,
};

const n: IMember = {
  id: "n",
  display: "N",
  avater: "n.jpg",
  bio: `育児に追われし者`,
  domains: ["ゲームデザイン", "レベルデザイン"],
  isRegular: true,
}

export const getMembers : () => {[id: string]: IMember} = () =>{
  const list: {[id: string]: IMember} = {};

  list[hassaku.id] = hassaku;
  list[duce.id] = duce;
  list[n.id] = n;
  
  return list;
}