import * as React from 'react';
import { Component } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from "../layout";
import { ArticleTitle } from "../atoms/headings/ArticleTitle";
import { HorizontalSeparator } from '../atoms/separators/HorizontalSeparator';
import { ArticleDate } from '../atoms/texts/ArticleDate';
import { SEO } from '../seo';
import { getMembers, IMember } from '../../constants/Member';
import { ArticleAuthors } from '../atoms/texts/ArticleAuthors';
import { CodeSyntaxHighlight } from '../helmets/CodeSyntaxHighlight';
import { HtmlAstRenderer } from '../organisms/HtmlAstRenderer';
import { Iframely } from '../helmets/Iframely';
import { ShareBelt } from '../organisms/ShareBelt';
import { Canonical } from '../helmets/Canonical';

const AuthorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export default class Article extends Component<any>{

  private getAuthors(post: any): string[]{
    const members = getMembers();
    const values: string[] = [];
    const authors = post.frontmatter.authors;
    const author = post.frontmatter.author;
    if(authors){
      authors.forEach(id =>{
        const targetMember: IMember = members[id];
        if(targetMember !== undefined) values.push(targetMember.display);
        else values.push(authors);
      });
    }
    else if(author){
      const targetMember: IMember = members[author];
      if(targetMember !== undefined) values.push(targetMember.display);
      else values.push(authors);
    }

    return values;
  }

  private getUrl(post: any): string{
    const slug: string = post.fields.slug;
    const home = this.props.data.site.siteMetadata.home;
    
    return `${home}/articles${slug}`;
  }

  private renderCanonical(post: any) : JSX.Element | null{
    if(post.frontmatter.canonical){
      return <Canonical link={post.frontmatter.canonical} />
    }
    else{
      return null;
    }
  }

  public render() : JSX.Element{
    const post = this.props.data.markdownRemark;

    const coverImage = post.frontmatter.cover ? post.frontmatter.cover.childImageSharp.fluid.src : undefined;
    const description = post.frontmatter.description || post.excerpt || ' ';

    return(
      <Layout>
        <SEO
          title={post.frontmatter.title}
          description={description}
          isArticle={true}
          cover={coverImage}
        />
        {this.renderCanonical(post)}
        <CodeSyntaxHighlight />
        <Iframely />
        <article>
          <ArticleTitle text={post.frontmatter.title} />
          <ArticleDate date={post.frontmatter.date} />
          <HorizontalSeparator />
          <HtmlAstRenderer htmlAst={post.htmlAst} slug={post.fields.slug} />
          <ShareBelt title={post.frontmatter.title} url={this.getUrl(post)} />
          <AuthorContainer>
            <ArticleAuthors authors={this.getAuthors(post)}/>
          </AuthorContainer>
        </article>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query ArticleBySlug($slug: String!) {
    site{
      siteMetadata{
        home
      }
    }
    markdownRemark(
      fileAbsolutePath: {regex: "/articles//"},
      fields: { slug: { eq: $slug } }
    ) {
      id
      excerpt(pruneLength: 160)
      htmlAst
      frontmatter {
        title
        date(formatString: "MM/DD YYYY")
        description
        cover{
          relativePath
          childImageSharp{
            fluid(maxWidth: 640, quality: 90){
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        author
        authors
        canonical
      }
      fields {
        slug
      }
    }
  }
`