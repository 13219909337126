import React, { FunctionComponent } from "react";
import { Helmet } from "react-helmet";

type Property = {
  link: string;
}

export const Canonical: FunctionComponent<Property> = (props) =>(
  <Helmet>
    <link rel="canonical" href={props.link} />
  </Helmet>
);